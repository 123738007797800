import {getCurrencyExchangeRate} from '@/api/twtAgentPortal';
import utils from '@/utils/index';
import axios from "axios";

export const getCurrencyRate =  async () => {
    let rate = await getCurrencyExchangeRate();
    utils.setItemLocalStorage('currencyRate', rate.data.response);
}

export const getInfoLocation = async () => {
   const geoResponse = await axios.get('http://www.geoplugin.net/json.gp');
   return geoResponse.data.geoplugin_request;
}