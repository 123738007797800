<template>
  <div class="pricing-detail" v-if="pricing.maxParticipants">
    <p class="pricing-type">
      {{pricing.label}} <span v-if="!pricing.label.toLowerCase().includes('child')">({{pricing.maxParticipants ?? 0}} {{`${pricing.maxParticipants > 1 ? 'spots' : 'spot'}` }} available)</span>
    </p>
    <p class="pricing-amount">
      {{formatPrice({gross: pricing.unitPrice.gross * amount}, $store.state.currency.name )}}
    </p>
    <div class="action">
      <font-awesome-icon icon="fa-solid fa-minus" @click="updateUnits('minus')"/>
      <span>{{amount}}</span>
      <font-awesome-icon icon="fa-solid fa-plus" @click="updateUnits('plus')"/>
    </div>
  </div>
</template>

<script>
import utils from '../../utils'
export default {
  name: "PricingDetail",
  props: ['pricing', 'updatePricingCategoryList'],
  data(){
    return {
      amount: 0
    }
  },
  methods : {
    ...utils,
    updateUnits: function (type){
      if (type == 'minus'){
        if (this.amount- 1 < 0){
          this.$swal({
            type: 'error',
            icon: 'error',
            title: 'Minimum spot is 0',
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false
          });
        } else{
          this.amount = this.amount  - 1;
          this.updatePricingCategoryList(this.pricing.id, this.amount)
        }
      } else{
        if (this.amount + 1 > this.pricing.maxParticipants){
          this.$swal({
            type: 'error',
            icon: 'error',
            title: 'No more spots available',
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false
          });
        } else{
          this.amount = this.amount  + 1;
          this.updatePricingCategoryList(this.pricing.id, this.amount)
        }
      }
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>