<template>
  <main-layout>
    <div class="container padding-content" v-if="Object.keys(product).length">
      <h3 id="product-name">{{ product.title }}</h3>
      <b-row>
        <b-col cols="8">
          <section id="image-gallery">
            <vue-slick-carousel v-if="product.images.length" v-bind="configSlideImage" class="arrow-in-section">
              <div v-for="image in product.images" :key="image" class="item-image">
                <img :src="image" alt="">
              </div>
            </vue-slick-carousel>
          </section>
        </b-col>
        <b-col cols="4">
          <section id="price-box">
            <span>Best Price Guarantee</span>
            <span v-if="product.provider" class="operator">Operator: {{ product.provider.name }}</span>
            <div class="row-info price">
              <span class="type">From</span>
              <p class="info">{{
                  formatPrice({
                    gross: product.price_gross,
                    net: product.price_net,
                    currency: product.price_currency
                  }, $store.state.currency.name, product.title.toLowerCase().includes('private'))
                }}</p>
            </div>
            <div class="row-info" v-if="product.duration && makeDurationString(product.duration)">
              <span><font-awesome-icon icon="fa-regular fa-clock"/></span>
              <p class="info">{{ makeDurationString(product.duration) }}</p>
            </div>
            <div class="row-info" v-if="product.is_free_cancel">
              <span><font-awesome-icon icon="fa-solid fa-ban"/></span>
              <p class="info">Free Cancellation</p>
            </div>
            <div class="row-info" v-if="product.country">
              <span><font-awesome-icon icon="fa-solid fa-map-pin"/></span>
              <p class="info"> {{ product.city.name ?? '' }} -
                {{ product.country.name == 'United States of America' ? 'USA' : product.country.name }}</p>
            </div>
            <div class="row-info instant">
              <span><font-awesome-icon icon="fa-solid fa-bolt-lightning"/></span>
              <p>Instant Confirmation</p>
            </div>
<!--            <b-button class="btn-main btn-booking" v-b-modal.modal-booking>Book Now</b-button>-->

             <b-button class="get-reward btn-transparent" @click="addToFavorites" >Add To Favorites<font-awesome-icon icon="fa-solid fa-heart" /> </b-button>
          </section>
        </b-col>
      </b-row>
      <section id="info-product">
        <div class="overview">
          <b-row>
            <b-col cols="8">
              <h4 class="title">Overview</h4>
              <p v-html="product.summary" style="white-space: pre-line"></p>
              <div class="list-collapse-content" v-if="Object.keys(product.information)">
                <div class="item-collapse" v-for="keyInformation in Object.keys(product.information)"
                     :key="keyInformation">
                  <p class="title" v-b-toggle="keyInformation"> {{ keyInformation.replaceAll('_', ' ') }}</p>
                  <b-collapse visible :id="keyInformation">
                    <div v-for="itemInformation in product.information[keyInformation]" :key="itemInformation.id">
                      <p v-if="itemInformation.name != null">
                        <font-awesome-icon :icon="['fas', 'check']"/>
                        {{ itemInformation.name }}
                      </p>
                      <p v-if="itemInformation.description != null">
                        <font-awesome-icon :icon="['fas', 'check']"/>
                        {{ itemInformation.description }}
                      </p>
                    </div>
                  </b-collapse>
                </div>
              </div>

            </b-col>
            <b-col cols="4" v-if="product.attributes.length">
              <div class="content-list  bg-white">
                <div class="content-list-item" v-for="attribute in product.attributes" :key="attribute.id">
                  <div class="icon">
                    <font-awesome-icon :icon="renderIconAttrProduct(attribute.level1)"/>
                  </div>
                  <div class="content">
                    <span class="title d-block">{{ attribute.level1 }}</span>
                    <span class="content d-block">{{ attribute.name }}</span>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>

        </div>
      </section>
      <section id="might-like" class="mt-5">
        <p class="title-wrap">Recommended activities <span>for you </span></p>
        <vue-slick-carousel v-if="recommendProduct.length" class="big-arrow" :arrows="true"
                            v-bind="slickRecommendConfig">
          <div v-for="product in recommendProduct" :key="product.code">
            <product-component :product="product"></product-component>
          </div>
        </vue-slick-carousel>
        <div class="text-center" v-else>
          <!--          <b-spinner type="grow" label="Spinning"></b-spinner>-->
          <!--          <b-spinner type="grow" label="Spinning"></b-spinner>-->
          <!--          <b-spinner type="grow" label="Spinning"></b-spinner>-->
        </div>
      </section>
    </div>
    <div class="container padding-content" v-else>
      <div class="text-center">
        <b-spinner type="grow" label="Spinning"></b-spinner>
        <b-spinner type="grow" label="Spinning"></b-spinner>
        <b-spinner type="grow" label="Spinning"></b-spinner>
      </div>
    </div>
    <modal-booking :product="product"/>
  </main-layout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSlickCarousel from 'vue-slick-carousel'
import ProductComponent from "@/components/product/ProductComponent";
// import {findByCode, getProductRecommend} from "@/repositories/product";
import productRepository from "@/repositories/product";
import utils from '@/utils/index';
import ModalBooking from "@/components/booking/ModalBooking";
import favoriteRepository from "@/repositories/favorite";

export default {
  name: "ProductDetailPage",
  components: {ModalBooking, MainLayout, VueSlickCarousel, ProductComponent},
  data: function () {
    return {
      slickRecommendConfig: {
        infinite: true,
        slidesToShow: 4,
        speed: 500,
        rows: 1,
        slidesPerRow: 1
      },
      configSlideImage: {
        "dots": false,
        "fade": true,
        "edgeFriction": 0.35,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "arrows": true,
        // "autoplay": true,
        // "autoplaySpeed": 2000,
      },
      product: {},
      recommendProduct: [],
    }
  },
  methods: {
    ...utils,
    addToFavorites: function () {
      if (this.$store.state.auth.access_token === null) {
        this.$bvModal.show('login-modal');
      } else {
        this.$store.commit('setting/loading', true)
        favoriteRepository.add(this.product.id).then((res) => {
          this.$store.commit('setting/loading', false)
          this.$store.commit('auth/setFavorite', res.data.response);
          this.$swal({
            type: 'success',
            icon: 'success',
            title: 'Successfully added to favorites',
            timer: 1500,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
          });
        }).catch(() => {
          this.$store.commit('setting/loading', false)
          this.$swal({
            type: 'success',
            icon: 'success',
            title: 'Failed to add to favorites',
            timer: 1500,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
          });
        });
      }
    }
  },
  created() {
    window.scrollTo(0, 0);
    if (localStorage.getItem(`product_detail_${this.$route.params.code}`) !== null) {
      this.product = JSON.parse(localStorage.getItem(`product_detail_${this.$route.params.code}`));
    }
    productRepository.findByCode(this.$route.params.code).then(res => {
      this.product = res;
      localStorage.setItem(`product_detail_${this.$route.params.code}`, JSON.stringify(res));
    });
    productRepository.recommend().then(res => this.recommendProduct = res);
  },
  beforeRouteUpdate(to, from, next) {
    this.product = {};
    this.recommendProduct = [];
    console.log(from);
    window.scrollTo(0, 0);
    if (localStorage.getItem(`product_detail_${to.params.code}`) !== null) {
      this.product = JSON.parse(localStorage.getItem(`product_detail_${to.params.code}`));
    }
    productRepository.findByCode(to.params.code).then(res => {
      this.product = res;
      localStorage.setItem(`product_detail_${to.params.code}`, JSON.stringify(res));
    });
    productRepository.recommend().then(res => this.recommendProduct = res);
    next();
  }
}
</script>

<style scoped>

</style>