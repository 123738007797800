<template>
  <b-modal id="modal-booking" @hidden="resetModalBooking" size="xl" :title="product.title" :hide-footer="step === 2 || step === 5"
           no-close-on-backdrop no-close-on-esc>
    <template #modal-title>
      <ol class="steps mb-0 w-100 hide-step-bar-twt">
        <li class="title_step title_step_1 " :class="{ current: step === 1 }"><a href="#" title=""> Booking Process</a>
        </li>
        <li class="title_step title_step_2 " :class="{ current: step === 2 }"><a href="#" title=""> Passenger
          Details</a></li>
        <li class="title_step title_step_3 " :class="{ current: step === 3 }"><a href="#" title=""> Booking Summary</a>
        </li>
        <li class="title_step title_step_4 " :class="{ current: step === 4 }"><a href="#" title="">Payment Options </a>
        </li>
        <li :class="{ current: step === 5 }"><a href="#" title=""> Submit </a></li>
      </ol>
    </template>
    <div class="list-step">
      <section id="step-1" :class="{'d-none' : step !== 1}">
        <b-form>
          <b-row>
            <b-col cols="3">
              <b-form-group
                  label="Departure date"
                  label-for="departure-date"
              >
                <div class="group-departure-date">
                  <datepicker @input="changeDepartureDate" :value="departureDate" :inline="true"
                              @changedMonth="changedMonth" :format="departureDateConfig.customFormatter"
                              :disabled-dates="departureDateConfig.disableDate"></datepicker>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="9" v-if="Object.keys(availability).length">
              <b-row class="option-list">
                <b-col cols="4" v-for="(option, key) in availability.optionList.nodes" :key="option.id">
                  <b-form-group :label="option.label">
                    <select class="form-control" :name="option.id"
                            v-model="availability.optionList.nodes[key].answerValue" @change="changeOptionAvailability">
                      <option v-for="available in option.availableOptions" :key="available.value"
                              :value="available.value">{{ available.label }}
                      </option>
                    </select>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="pricing-list" v-if="availability.pricingCategoryList?.nodes">
                <pricing-detail
                    v-for="pricing in availability.pricingCategoryList.nodes"
                    :key="pricing.id"
                    :pricing="pricing"
                    :local-value-pricing="optionsAvailability.pricingCategoryList.filter(item => item.id == pricing.id)[0]"
                    :update-pricing-category-list="updatePricingCategoryList"
                />
                <div class="line"></div>
                <div class="pricing-detail">
                  <p class="pricing-type font-bold">
                    Total Price
                  </p>
                  <p class="pricing-amount">
                    {{ formatPrice({gross: totalPrice}, $store.state.currency.name) }}
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </section>
      <section id="step-2" :class="{'d-none' : step !== 2}">
        <ValidationObserver v-slot="{handleSubmit}">
          <form id="form-passenger" @submit.prevent="handleSubmit(submitFormPassenger)">
            <div class="area-passenger" v-for="pricing in optionsAvailability.pricingCategoryList"
                 :key="`${pricing.id}`">
              <h5>{{ getPricingById(pricing.id).label }}</h5>
              <div class="passenger-item" v-for="n in pricing.units" :key="`${pricing.id}-${n}`">
                <div class="input-group-passenger">
                  <div class="title">
                    <div v-if="!passengers[`${pricing.id}`]"></div>
                    <ValidationProvider v-else rules="required" v-slot="{errors}">
                      <select :state="!errors.length" :name="`title[${pricing.id}][${n}]`" :class="{'is-invalid' : errors.length}"
                              v-model="passengers[`${pricing.id}`][n-1].title" class="form-control">
                        <option value="" selected disabled>Title</option>
                        <option value="Mr.">Mr.</option>
                        <option value="Mrs.">Mrs.</option>
                        <option value="Ms.">Ms.</option>
                        <option value="Mstr.">Mstr.</option>
                        <option value="Miss.">Miss.</option>
                        <option value="Dr.">Dr.</option>
                        <option value="Prof.">Prof.</option>
                        <option value="Sir.">Sir.</option>
                      </select>
                      <b-form-invalid-feedback class="invalid-feedback d-block">{{ errors[0] }}
                      </b-form-invalid-feedback>
                    </ValidationProvider>
                  </div>
                  <div class="first-name">
                    <div v-if="!passengers[`${pricing.id}`]"></div>
                    <ValidationProvider v-else rules="required" v-slot="{errors}">
                      <b-form-input :name="`firstName[${pricing.id}][${n}]`" :class="{'is-invalid' : errors.length}"
                                    v-model="passengers[`${pricing.id}`][n-1].firstName"
                                    placeholder="First name*"></b-form-input>
                      <b-form-invalid-feedback class="invalid-feedback d-block">{{ errors[0] }}
                      </b-form-invalid-feedback>
                    </ValidationProvider>
                  </div>
                  <div class="last-name">
                    <div v-if="!passengers[`${pricing.id}`]"></div>
                    <ValidationProvider v-else rules="required" v-slot="{errors}">
                      <b-form-input :name="`lastName[${pricing.id}][${n}]`" :class="{'is-invalid' : errors.length}"
                                    v-model="passengers[`${pricing.id}`][n-1].lastName"
                                    placeholder="Last name*"></b-form-input>
                      <b-form-invalid-feedback class="invalid-feedback d-block">{{ errors[0] }}
                      </b-form-invalid-feedback>
                    </ValidationProvider>
                  </div>
                  <div class="date-of-birth">
                    <div v-if="!passengers[`${pricing.id}`]"></div>
                    <ValidationProvider v-else rules="required" v-slot="{errors}">
                      <input type="date" class="form-control" :max="formatDate(maxDateOfBirth, 'YYYY-MM-DD')" v-model="passengers[`${pricing.id}`][n-1].dateOfBirth"   :name="`dateOfBirth[${pricing.id}][${n}]`"
                             :class="{'is-invalid' : errors.length}" placeholder="Birthday*">
<!--                      <b-form-datepicker v-model="passengers[`${pricing.id}`][n-1].dateOfBirth"-->
<!--                                         :name="`dateOfBirth[${pricing.id}][${n}]`"-->
<!--                                         :class="{'is-invalid' : errors.length}"-->
<!--                                         :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"-->
<!--                                         :max="maxDateOfBirth"-->
<!--                                         placeholder="Birthday*"></b-form-datepicker>-->
                      <b-form-invalid-feedback class="invalid-feedback d-block">{{ errors[0] }}
                      </b-form-invalid-feedback>
                    </ValidationProvider>
                  </div>
                  <div class="nationality">
                    <div v-if="!passengers[`${pricing.id}`]"></div>
                    <ValidationProvider v-else rules="required" v-slot="{errors}">
                      <v-select :options="countries.map(item => `${item.label}`)" :name="`nationality[${pricing.id}][${n}]`" :class="{'is-invalid' : errors.length}"
                                v-model="passengers[`${pricing.id}`][n-1].nationality" placeholder="Country of Passport*"></v-select>
                      <b-form-invalid-feedback class="invalid-feedback d-block">{{ errors[0] }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </div>
                  <div class="email" v-if="!getPricingById(pricing.id).label.toLowerCase().includes('child')">
                    <div v-if="!passengers[`${pricing.id}`]"></div>
                    <ValidationProvider v-else rules="required|email" v-slot="{errors}">
                      <b-form-input v-model="passengers[`${pricing.id}`][n-1].email" :class="{'is-invalid' : errors.length}"
                                    :name="`email[${pricing.id}][${n}]`" placeholder="Email *"></b-form-input>
                      <b-form-invalid-feedback class="invalid-feedback d-block">{{ errors[0] }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </div>
                  <div
                      v-if="!passengers[`${pricing.id}`] && !getPricingById(pricing.id).label.toLowerCase().includes('child')"></div>
                  <div v-else class="phone-number">
                    <ValidationProvider v-if="!getPricingById(pricing.id).label.toLowerCase().includes('child')" rules="required" v-slot="{errors}">
                      <v-select :options="countries.map(item => `+${item.phone}`).filter((value, index, self) => self.indexOf(value) === index)"  :name="`countryCode[${pricing.id}][${n}]`"  placeholder="Code*"
                                v-model="passengers[`${pricing.id}`][n-1].countryCode"  :class="{'is-invalid' : errors.length}"></v-select>

                      <b-form-invalid-feedback class="invalid-feedback d-block">{{ errors[0] }}</b-form-invalid-feedback>
                    </ValidationProvider>
                    <ValidationProvider v-if="!getPricingById(pricing.id).label.toLowerCase().includes('child')" rules="numeric|required" v-slot="{errors}">
                      <b-form-input v-model="passengers[`${pricing.id}`][n-1].phoneNumber"
                                    :class="{'is-invalid' : errors.length}"
                                    :name="`phoneNumber[${pricing.id}][${n}]`" placeholder="Phone Number*"></b-form-input>
                      <b-form-invalid-feedback class="invalid-feedback d-block">{{ errors[0] }}</b-form-invalid-feedback>
                    </ValidationProvider>

                  </div>
                  <div class="leader" v-if="!getPricingById(pricing.id).label.toLowerCase().includes('child')">
                    <div v-if="!passengers[`${pricing.id}`]"></div>
                    <b-form-radio v-else name="leader" @change="changeLeader(pricing.id, n-1)" v-model="passengers[`${pricing.id}`][n-1].leader" :checked="passengers[`${pricing.id}`][n-1].leader" :value="true">Leader</b-form-radio>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer-modal footer-step-2">
              <b-button @click="backStep" :class="{opacity: step === 1 || step === 5}" :disabled="loadingData">Back</b-button>
              <b-button class="btn-main" type="submit" :class="{opacity: step === 5}" :disabled="loadingData"> <b-spinner v-if="loadingData" small type="grow"></b-spinner> Next</b-button>
            </div>
          </form>
        </ValidationObserver>
      </section>
      <section id="step-3" :class="{'d-none' : step !== 3}">
        <div class="row-info">
          <p class="info">Product Name: <span>{{ product.title }}</span></p>
        </div>
        <div class="row-info">
          <p class="info">Departure Date: <span>{{ formatDate(departureDate, 'dddd DD-MMMM-gggg') }}</span></p>
        </div>
        <b-row>
          <b-col cols="3">
            <b-img :src="product.thumbnail_url" thumbnail></b-img>
          </b-col>
          <b-col cols="5">
            <table class="b-table table w-100 table-price mb-0">
              <thead>
              <tr>
                <th scope="col">Quantity</th>
                <th scope="col">Price ({{ $store.state.currency.name }})</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="pricing in optionsAvailability.pricingCategoryList" :key="pricing.id">
                <td>{{ getPricingById(pricing.id).label }}: {{ pricing.units }} x
                  {{ formatPrice(getPricingById(pricing.id).unitPrice, $store.state.currency.name) }}
                </td>
                <td>
                  {{ formatPrice({gross: getPricingById(pricing.id).unitPrice.gross * pricing.units}, $store.state.currency.name) }}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>{{ formatPrice({gross: totalPrice}, $store.state.currency.name) }}</td>
              </tr>
              </tbody>
            </table>
          </b-col>
          <b-col cols="4">
            <b-form-textarea
                v-model="noteClient"
                id="textarea"
                placeholder="Note from Client / Agent"
                rows="5"
            ></b-form-textarea>
            <b-form-checkbox
                id="checkbox-term"
                :value="true"
                :unchecked-value="false"
                :class="'mt-2'"
                v-model="acceptTerm"
            >
              I accept the <a href="https://agentportal.tweetworldtravel.com/terms-conditions" target="_blank"
                              style="color: rgb(0, 123, 255) !important;">Terms and Conditions*</a>
            </b-form-checkbox>
          </b-col>
        </b-row>
      </section>
      <section id="step-4" :class="{'d-none': step !== 4}">
        <p>Please select the payment option/amount below: </p>
        <h5 class="mb-4">SELECT YOUR PAYMENT METHOD</h5>
        <b-tabs pills card vertical v-model="tabPaymentIndex" class="tab-payment-method">
          <b-tab title="Electronic Fund Transfer (EFT)" active class="tab-eft">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio v-model="EFTMethod" :aria-describedby="ariaDescribedby" name="some-radios" value="M">Mint
                EFT
              </b-form-radio>
              <b-form-radio v-model="EFTMethod" :aria-describedby="ariaDescribedby" name="some-radios" value="TP">Travel
                Pay EFT
              </b-form-radio>
            </b-form-group>
            <p>Supplier Code: {{ EFTMethod === 'M' ? 'M309594' : 'TPStweet' }} </p>
          </b-tab>
          <b-tab title="Direct Credit">
            <p>Please make payments to the below WESTPAC Bank Account</p>
            <div class="row-info">
              <p class="info">Account Name: <span>Tweet World Travel Tour Operator & Wholesaler Pty. Ltd</span></p>
            </div>
            <div class="row-info">
              <p class="info">BSB: <span>035-052</span></p>
            </div>
            <div class="row-info">
              <p class="info">Account No: <span>384069</span></p>
            </div>
            <div class="row-info">
              <p class="info">International Bank Transfer SWIFT Code: <span>WPACAU2S</span></p>
            </div>
            <p>Please advise payment to: contact@tweetworldtravel.com.au</p>
          </b-tab>
          <b-tab title="Pay by PayPal">
            <b-card-text>All transactions are secure and encrypted with PayPal Checkout</b-card-text>
          </b-tab>
          <b-tab title="With Credit Card">
            <p>We Accept</p>
            <img src="@/assets/images/payment-method.png" alt="">
            <p class="mt-4 mb-0">All transaction are secure and encrypted</p>
          </b-tab>
        </b-tabs>
        <h5 class="mt-4">SELECT YOUR PAYMENT TYPE</h5>
        <b-row>
          <b-col cols="6">
            <div class="payment-table">
              <div class="border-payment-type">
                <div class="total-price">
                  {{ formatPrice({gross: totalPrice}, $store.state.currency.name) }} gross due
                </div>
                <table class="b-table table table-bordered w-100 mb-0">
                  <thead>
                  <tr>
                    <th></th>
                    <th>Price</th>
                    <th>Due Date</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th>Price</th>
                    <td>{{ formatPrice({gross: totalPrice}, $store.state.currency.name) }}</td>
                    <td>{{ formatDate(departureDate, 'DD-MMMM-gggg') }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="payment-table">
              <div class="border-payment-type">
                <div class="total-price">
                  {{ formatPrice({gross: totalPrice}, $store.state.currency.name) }} net due
                </div>
                <table class="b-table table-bordered table w-100 mb-0">
                  <thead>
                  <tr>
                    <th></th>
                    <th>Price</th>
                    <th>Due Date</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th>Price</th>
                    <td>{{ formatPrice({gross: totalPrice}, $store.state.currency.name) }}</td>
                    <td>{{ formatDate(departureDate, 'DD-MMMM-gggg') }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-col>
        </b-row>
      </section>
      <section id="step-5" :class="{'d-none' : step !== 5}">
        <font-awesome-icon icon="fa-solid fa-circle-check"/>
        <span>Thank you!</span>
        <p>Thank you for choosing Tweet World Travel Tour Operator & Wholesaler. Your booking request reference id:
          {{bookingInfo.order_code ?? ''}}</p>
        <p>We have received your booking request and it is being processed by our operation team. We will be in touch
          with you shortly about your booking request.</p>
        <p>For further enquiries, please contact our Head Office via email <a
            href="mailto:contact@tweetworldtravel.com.au">contact@tweetworldtravel.com.au</a> or call us on <strong>1800
          519 631</strong></p>
        <div class="button-close text-right w-100">
          <b-button @click="$bvModal.hide('modal-booking')">Close</b-button>
        </div>
      </section>
    </div>
    <template #modal-footer v-if="step !== 2 && step !== 5">
      <div class="footer-modal"
           :class="{'d-none': !optionsAvailability.pricingCategoryList.filter(item => item.units > 0).length}">
        <b-button @click="backStep" :class="{opacity: step === 1 || step === 5 || step === 4}" :disabled="loadingData">Back</b-button>
        <b-button class="btn-main" @click="nextStep" :disabled="loadingData"
                  :class="{opacity: step === 5 || (step === 3 && !acceptTerm) || (step === 4 && ((tabPaymentIndex > 1) || (tabPaymentIndex === 0 && EFTMethod === 'M')))}">
          <b-spinner v-if="loadingData" small type="grow"></b-spinner>
          Next
        </b-button>
      </div>
    </template>
    <div class=" loading" v-if="loadingData">
      <div class="text-center">
        <b-spinner type="grow" label="Spinning"></b-spinner>
        <b-spinner type="grow" label="Spinning"></b-spinner>
        <b-spinner type="grow" label="Spinning"></b-spinner>
      </div>
    </div>
  </b-modal>
</template>
<script>
import {extend} from 'vee-validate';
import {required, email, numeric} from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Required'
});
extend('email', {
  ...email,
  message: 'Email'
});
extend('numeric', {
  ...numeric,
  message: 'Numeric'
});
extend('email', email);
import utils from '../../utils'
import Datepicker from 'vuejs-datepicker';
import countries from '../../utils/data/country';
import moment from "moment";
import productRepository from "@/repositories/product";
import PricingDetail from "@/components/booking/PricingDetail";
import bookingRepository from "@/repositories/booking";

export default {
  name: "ModalBooking",
  props: ['product'],
  components: {PricingDetail, Datepicker},
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    return {
      maxDateOfBirth: new Date(today),
      EFTMethod: "M",
      tabPaymentIndex: 1,
      noteClient: '',
      acceptTerm: false,
      countries: countries,
      step: 1,
      departureDate: null,
      loadingData: true,
      departureDateConfig: {
        customFormatter: function (date) {
          return moment(date).format('YYYY/MM/DD');
        },
        disableDate: {
          to: moment().subtract(1, 'days').toDate(),
          dates: []
        },
      },
      availabilityList: [],
      monthDidFetchAvailability: [moment().format('YYYY-MM')],
      availability: {},
      optionsAvailability: {
        optionList: [],
        pricingCategoryList: []
      },
      firstLoadAvailability: false,
      passengers: {},
      totalPrice: 0,
      bookingInfo: {},
    }
  },
  validations: {},
  methods: {
    ...utils,
    test: function () {
      console.log('test');
    },
    changedMonth: function (e) {
      if (typeof e.getMonth !== 'function') e = moment(e.timestamp);
      let dateChange = moment(e).format('YYYY-MM');
      if (!this.monthDidFetchAvailability.includes(dateChange)) {
        this.monthDidFetchAvailability.push(dateChange);
        this.loadingData = true;
        productRepository.availabilityList(this.product.id, this.monthDidFetchAvailability[this.monthDidFetchAvailability.length - 1]).then(res => {
          this.loadingData = false;
          let daysInMonth = moment(e).daysInMonth();
          let monthChange = (moment(e).month() + 1) > 9 ? (moment(e).month() + 1) : `0${moment(e).month() + 1}`;
          let monthSystem = moment(e).month();
          let year = moment(e).year();
          for (let i = 1; i <= daysInMonth; i++) {
            let date = `${year}-${monthChange}-${i < 10 ? `0${i}` : i}`;
            if (!res.length) {
              this.departureDateConfig.disableDate.dates.push(new Date(year, monthSystem, i));
            } else {
              if (!res.filter(item => item.date == date).length) {
                this.departureDateConfig.disableDate.dates.push(new Date(year, monthSystem, i));
              }
            }
          }
          this.availabilityList = [...this.availabilityList, ...res];
        })
      }
    },
    changeDepartureDate: function (e) {
      this.departureDate = moment(e).format('YYYY-MM-DD');
    },
    changeOptionAvailability: function (e) {
      this.firstLoadAvailability = false;
      let currentOptionAvailability = {...this.optionsAvailability};
      currentOptionAvailability.optionList.forEach((item, key) => {
        if (item.id == e.target.name) {
          currentOptionAvailability.optionList[key].value = e.target.value;
        }
      });
      currentOptionAvailability.pricingCategoryList = [];
      this.optionsAvailability = currentOptionAvailability;
    },
    updatePricingCategoryList: function (id, units) {
      let currentOptionAvailability = {...this.optionsAvailability};
      this.firstLoadAvailability = false;
      let pricingCategoryList = [];
      this.availability.pricingCategoryList.nodes.forEach(pricing => {
        if (pricing.id == id) {
          pricingCategoryList.push({
            id: id,
            units: units
          });
        } else {
          let currentPricing = currentOptionAvailability.pricingCategoryList.filter(item => item.id == pricing.id);
          if (currentPricing.length) {
            pricingCategoryList.push({
              id: pricing.id,
              units: currentPricing[0].units
            })
          }
        }
      });
      currentOptionAvailability.pricingCategoryList = pricingCategoryList;
      this.optionsAvailability = currentOptionAvailability;
    },
    getPricingById: function (id) {
      return this.availability.pricingCategoryList.nodes.filter(item => item.id == id)[0];
    },
    nextStep: async function () {
      if (!this.loadingData){
        if (this.step == 3){
          let dataQuote = {
            productId: JSON.parse(JSON.stringify(this.product)).id,
            departureDate: this.departureDate,
            availabilityId: JSON.parse(JSON.stringify(this.availability)).id,
            optionsAvailability: JSON.parse(JSON.stringify(this.optionsAvailability)),
            clientNote: this.noteClient,
            passengers: this.passengers
          }
          this.loadingData = true;
          let response = await bookingRepository.createQuote(dataQuote);
          this.bookingInfo = response.data.response;
          this.loadingData = false;
          this.step = this.step + 1;
        } else if(this.step == 4){
          let paymentMethod = 1;
          if (this.tabPaymentIndex === 1){
            paymentMethod = 3;
          } else{
            if (this.tabPaymentIndex === 0){
              paymentMethod = this.EFTMethod === 'M' ? 1 : 2
            }
          }
          const dataBooking = {
            booking_id: this.bookingInfo.booking_id,
            order_code: this.bookingInfo.order_code,
            payment_method: paymentMethod,
            payment_type: '',
            payment_amount: ''
          }
          this.loadingData = true;
          let response = await bookingRepository.convertQuoteToBooking(dataBooking);
          console.log(response);
          this.loadingData = false;
          this.step = this.step + 1;
        } else{
          this.step = this.step + 1;
        }
      }
    },
    backStep: function () {
      this.step = this.step - 1;
    },
    submitFormPassenger: function () {
      this.step = this.step + 1;
    },
    changeLeader: function (pricingId, unit){
      let currentPassengers = this.passengers;
      Object.keys(currentPassengers).forEach(item => {
        currentPassengers[`${item}`].forEach((passenger, index) => {
          if (item == pricingId && index == unit){
            console.log('change leader');
          } else{
            currentPassengers[`${item}`][index].leader = false;
          }
        })
      });
    },
    resetModalBooking: function (){
      this.EFTMethod = "M";
      this.tabPaymentIndex = 1;
      this.noteClient = '';
      this.acceptTerm = false;
      this.step = 1;
      this.availability = {};
      this.optionsAvailability = {
        optionList: [],
        pricingCategoryList: []
      };
      this.firstLoadAvailability = true;
      this.passengers = {};
      this.totalPrice= 0;
      this.bookingInfo = {};
      this.departureDate = null;
      this.loadingData = false;
    }
  },
  watch: {
    product(newProduct) {
      this.monthDidFetchAvailability = [moment().format('YYYY-MM')];
      if (newProduct.id) {
        productRepository.availabilityList(this.product.id, this.monthDidFetchAvailability[0]).then(res => {
          this.loadingData = false;
          let e = moment().toDate();
          let daysInMonth = moment(e).daysInMonth();
          let monthReal = moment(e).month();
          let month = monthReal + 1;
          month = month < 10 ? `0${month}` : month;
          let year = moment(e).year();
          for (let i = 1; i <= daysInMonth; i++) {
            let date = `${year}-${month}-${i < 10 ? `0${i}` : i}`;
            if (!res.length) {
              this.departureDateConfig.disableDate.dates.push(new Date(year, monthReal, i));
            } else {
              if (!res.filter(item => item.date == date).length) {
                this.departureDateConfig.disableDate.dates.push(new Date(year, monthReal, i));
              }
            }
          }
          this.availabilityList = [...this.availabilityList, ...res];
        });
      }
    },
    departureDate(newDepartureDate) {
      if (newDepartureDate != null){
        this.firstLoadAvailability = true;
        let availabilityId = this.availabilityList.filter(item => item.date == moment(newDepartureDate).format('YYYY-MM-DD'))[0].id;
        this.loadingData = true;
        this.optionsAvailability = {
          optionList: [],
          pricingCategoryList: []
        }
        productRepository.availability(availabilityId, this.optionsAvailability).then(res => {
          this.loadingData = false;
          this.availability = res;
          let newOptionsAvailability = {
            optionList: [],
            pricingCategoryList: []
          }
          this.availability.optionList.nodes.forEach(option => {
            newOptionsAvailability.optionList.push({
              id: option.id,
              value: option.answerValue
            });
          });
          this.optionsAvailability = newOptionsAvailability;
        });
      }
    },
    optionsAvailability(newOptionAvailability) {
      if (!this.firstLoadAvailability) {
        this.loadingData = true;
        productRepository.availability(this.availability.id, newOptionAvailability).then(res => {
          this.availability = res;
          this.totalPrice = 0;
          newOptionAvailability.optionList = [];
          res.optionList.nodes.forEach(item => {
            newOptionAvailability.optionList.push({
              id: item.id,
              value: item.answerValue
            })
          });
          if (res.pricingCategoryList?.nodes != undefined){
            res.pricingCategoryList.nodes.forEach(item => {
              this.totalPrice += item.units * item.unitPrice.gross;
            });
            let newPassengers = {};
            let firstLeader = true;
            newOptionAvailability.pricingCategoryList.forEach(pricing => {
              newPassengers[`${pricing.id}`] = [];
              for (let i = 1; i <= pricing.units; i++) {
                newPassengers[`${pricing.id}`].push({
                  label: this.getPricingById(pricing.id).label,
                  title: '',
                  firstName: '',
                  lastName: '',
                  dateOfBirth: '',
                  nationality: '',
                  email: '',
                  countryCode: '',
                  phoneNumber: '',
                  leader: firstLeader
                });
                firstLeader = false;
              }
            });
            this.passengers = newPassengers;
            this.loadingData = false;
          } else{
            newOptionAvailability.pricingCategoryList = [];
            this.passengers = {}
            this.loadingData = false;
          }
          this.firstLoadAvailability = true;
          this.optionsAvailability = newOptionAvailability;
        });
      }
    },
  },
  async created() {
  },

}
</script>

<style scoped>

</style>